.image {
  width: 100%;
  height: 312px;
  object-fit: contain;
}

@media (max-width: 1100px) {
  .image {
    height: 150px;
    object-fit: contain;
  }
}
