.question-number-style {
  display: flex;
  justify-content: center;
  background-color: #039;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-items: center;
}

.question-style {
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.4px;
}
.questionNumberStyle {
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.4px;
}
.MuiFormControl-root {
  width: 100% !important;
}
@media (max-width: 768px) {
  .bodyWithoutPadding > div,
  .bodyWithoutPadding > div {
    align-items: flex-start !important;
  }

  .bodyWithoutPadding > div h5,
  .bodyWithoutPadding > div h5 {
    margin: 4px 0 0 0 !important;
  }
  .question-style {
    font-style: normal;
    font-size: 17px;
    letter-spacing: 0.4px;
    font-family: Helvetica !important;
    white-space: pre-wrap;
  }
}
.MuiFormControlLabel-root {
  width: 100% !important;
  margin-bottom: 0 !important;
}
