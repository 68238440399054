.signup-officer-inputfield {
  border: 1px solid #c4c4c4;
}

.signup-officer-inputfield:hover {
  border: 1px solid #323c47;
}

.signup-officer-autocomplete {
  margin-top: 24px;
}

.signup-officer-autocomplete > div {
  border-radius: 12px !important;
}
