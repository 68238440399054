.exportButtonInternal {
  color: #323c47 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
}
.exportButtonExternal {
  border-radius: 20px;
  width: 120px;
  background-color: #00349a !important;
}
.exportNotesButtonExternal {
  background-color: #00349a !important;
  border-radius: 5px;
  align-items: center;
  height: 36px !important;
  margin-right: 10px;
}
.exportButtonLink {
  margin-left: 12px;
  margin-right: 12px;
  color: #ffffff;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
a:hover {
  color: #323c47 !important;
}

a.exportButtonLink:hover {
  color: #ffffff !important;
  text-decoration-line: none;
}

.gridPadding {
  padding: 0px !important;
}

.tabClass {
  border: 1px solid #323c47 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 4px !important;
  height: 40px;
  min-height: 0px !important;
}
.tabclassColorOne {
  background-color: #00349a !important;
  color: #ffffff !important;
}

.tabclassColorTwo {
  background-color: #ffffff !important;
  color: #323c47 !important;
}

.Supervisor-pss-total {
  width: 100%;
}

.Supervisor-pss-total .pss-score{
  flex-direction: row !important;
  justify-content: space-evenly !important;
}

.Supervisor-pss-total .pss-score .pss-rateing{
  width: 100px;
}

.Supervisor-pss-total .pss-score .pss-label{
  font-size: 12px;
}
