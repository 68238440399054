.translate-survey {
  max-width: 500px;
  width: 40%;
  padding: 11px 31px 35px 9px;
}

.translate-survey .generate-survey-main {
  max-height: 754px;
  height: 100%;
  border-bottom: #bfc0c9 solid 1px;
}

.translate-survey .generate-survey-main .generate-survey-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 42px;
  color: #1c1d21;
}

.translate-survey .generate-survey-main .generate-survey-hint {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #a6a8b0;
}

.translate-survey .generate-survey-main .generate-survey-hint-url a {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: blue;
}

.translate-survey .generate-survey-main .translate-overview {
  margin-top: 32px;
  margin-bottom: 8px;
}

.translate-survey .generate-survey-main .translate-overview .type-field {
  height: 470px;
  background: #ffffff;
  border: 1px solid #a6a8b0;
  border-radius: 8px;
  padding: 16px;
}

.translate-survey .generate-survey-main .translate-overview .type-field:focus {
  border: 2px solid #00349a;
}

.translate-survey .generate-survey-main .translate-lang {
  margin-top: 32px;
}

.translate-survey .generate-survey-main .translate-lang .audience-field {
  height: 60px;
  background: #ffffff;
  border: 1px solid #a6a8b0;
  border-radius: 8px;
  padding: 16px;
}

.translate-results {
  margin-top: -24px;
  max-width: 928px;
  max-height: 891px;
  width: 60%;
  height: 891px;
  background-color: #ffffff;
  border-left: #bfc0c9 1px solid;
  padding: 35px 24px 24px;
}

.translate-results .result-box {
  max-height: 712px;
  width: 100%;
  height: 100%;
  border-bottom: #bfc0c9 1px solid;
  overflow-y: auto;
  overflow-x: auto;
}

.translate-results .results-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  color: #1c1d21;
}

@media screen and (max-width: 1366px) {
  .translate-survey {
    padding: 11px 15px 15px 0px;
    width: 40%;
  }

  .translate-survey .generate-survey-main {
    max-height: none;
    height: fit-content;
    border-bottom: #bfc0c9 solid 1px;
    padding-bottom: 30px;
  }

  .translate-survey .generate-survey-main .generate-survey-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 42px;
    color: #1c1d21;
  }

  .translate-survey .generate-survey-main .translate-overview {
    margin-top: 10px;
  }

  .translate-survey .generate-survey-main .translate-overview .type-field {
    height: 240px;
    background: #ffffff;
    border: 1px solid #a6a8b0;
    border-radius: 8px;
    padding: 16px;
  }

  .translate-survey .generate-survey-main .translate-lang {
    margin-top: 10px;
  }

  .translate-survey .generate-survey-main .translate-lang .audience-field {
    height: 54px;
    background: #ffffff;
    border: 1px solid #a6a8b0;
    border-radius: 8px;
    padding: 16px;
  }

  .translate-results {
    margin-top: -24px;
    max-width: 762px;
    max-height: 891px;
    width: 60%;
    height: 624px;
    background-color: #ffffff;
    border-left: #bfc0c9 1px solid;
    padding: 35px 24px 24px;
  }

  .translate-results .result-box {
    max-height: 454px;
    width: 100%;
    height: 100%;
    border-bottom: #bfc0c9 1px solid;
    overflow-y: auto;
    overflow-x: auto;
  }

  .translate-results .results-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #1c1d21;
  }
}

@media screen and (max-width: 992px) {
  .translate-survey {
    margin-bottom: 54px;
    width: 100%;
  }

  .translate-results {
    margin-top: 12px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .translate-survey {
    padding: 0;
  }

  .translate-survey .generate-survey-main {
    height: fit-content;
  }

  .translate-survey .generate-survey-main .translate-overview {
    margin-top: 32px;
  }

  .translate-survey .generate-survey-main .translate-overview .type-field {
    height: 72px;
  }

  .translate-survey .generate-survey-main .translate-lang {
    margin-top: 32px;
  }

  .translate-survey .generate-survey-main .translate-lang .audience-field {
    height: 48px;
  }

  .translate-results {
    margin-top: 0px;
    min-width: 390px;
  }
}
