.input {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  box-sizing: border-box;
  border-radius: 16px !important;
  border: 0px solid #a6a8b0 !important;
  background-color: #ffffff !important;
}
