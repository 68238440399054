.primaryButton {
  background-color: #108572;
  border: none;
  color: white;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.secondaryButton {
  background-color: #ffffff;
  color: #323c47;
  cursor: pointer;
  border: 1px solid #323c47;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.dangerButton {
  background-color: #c12a31;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.greyButton {
  background-color: #c4c4c4;
  color: #323c47;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.officerButton {
  background-color: #003399;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.lightBlueButton {
  background-color: #5b70e9;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.darkRedButton {
  background-color: #b23837;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.blackButton {
  background-color: #000000;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.lightCyanButton {
  background-color: #eef4ff;
  color: #003399;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.lightPinkButton {
  background-color: #ffe9e9;
  color: #eb5e5e;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.transparentButton {
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  color: #003399;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.iconButton {
  background-color: transparent;
  color: #323c47;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.smallButton {
  width: 95px;
  height: 35px;
}

.mediumButton {
  width: 135px;
  height: 35px;
}

.largeButton {
  width: 185px;
  height: 35px;
}

.extraLargeButton {
  width: 200px;
  height: 56px;
}

.fullWidth {
  width: 100%;
}

@media screen and (max-width: 425px) {
  .extraLargeButton {
    width: 100%;
  }
}
