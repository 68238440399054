.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
