.officerNameStyle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 48px;
}
.officerSurveyImage {
  width: 160px;
  height: 160px;
  left: 640px;
  top: 420px;

  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.officerSurveyBadgeHeading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #707683;
}
.officerSurveyBadgeNumber {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #c12a31;
}
.citizenInfoStyle {
  margin-top: -55px;
}
.citizenInfoBox {
  margin-top: -55px;
}
@media (max-width: 768px) {
  .officerSurveyBadgeHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height */

    color: #707683;
  }
  .officerSurveyBadgeNumber {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height */

    color: #c12a31;
  }
  .officerNameStyle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 48px;
  }
  .citizenInfoStyle {
    margin-top: -3px;
  }
  .citizenInfoBox {
    margin-top: -2px;
  }
}
