.filterFontSize {
  margin-bottom: 15px;
  font-size: 17px !important;
  font-weight: bold !important;
}
.pssFont {
  color: #fbbf47;
}
.totalRatingFont {
  color: purple;
}

button.btn-export {
  background: #c12a31;
  border-radius: 4px;
  color: #ffffff;
  padding: 7px 4px 6px 4px;
  max-width: 120px;
  min-width: 107px;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  border: none;
}

.donut-chart {
  width: 320px;
  height: 240px;
}

.donut-chart strong {
  font-weight: 500;
  font-size: 30px;
}

.view-demographic {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #003399;
  cursor: pointer;
  margin-bottom: 20px;
}

.Supervisor-pss-total .pss-score {
  background: #f5f6f8;
  border-radius: 8px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none !important;
  margin: 10px auto;
  max-width: 100%;
  width: 100%;
  min-width: auto;
}

.Supervisor-pss-total .pssScore-Supervisor {
  background: #f5f6f8;
  border-radius: 8px;
  /* height: 60px; */
  border: none !important;
  margin-top: 0px !important;
  max-width: 100%;
  min-width: auto;
}

.Supervisor-pss-total {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
  border-radius: 5px;
  margin-top: 12px;
  max-width: 410px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}
.Supervisor-pss-total-wrap {
  display: flex;
  justify-content: center;
  align-items: safe;
}

.Supervisor-pie-chart {
  display: flex;
  justify-content: center;
}

.public-graph-star {
  justify-content: center;
}

.Supervisor-star-rating-wrap .donut-graph-star {
  font-family: 'Poppins' !important;
}

.Supervisor-pie-chart .NTxAv {
  min-height: 0px;
}

.district-dropdown-list {
  width: 100%;
  height: 56px;
}
