.model-officerdailog button.secondaryButton:not(:disabled) {
  background-color: #003399 !important;
  color: #fff;
}

.add-officer-inputfield {
  border: 1px solid #c4c4c4;
}

.add-officer-inputfield:hover {
  border: 1px solid #323c47;
}

.add-officer-autocomplete > div {
  border-radius: 12px !important;
}
