.surveysHeading {
  color: #003399 !important;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px 4px 4px rgb(0 0 0 / 25%);
  font-weight: 900;
  font-size: 28px;
}

.secContent {
  font-weight: 600;
  margin-bottom: 30px !important;
}

.surveyMainWrapper {
  min-height: 100vh;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sec-heading-title {
  margin-bottom: 20px;
}

.sec-heading-title svg {
  margin-right: 5px;
  height: 19px;
}

.csv-sms-buttons svg {
  float: right;
}

.actionBtnWrapper button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionBtnWrapper svg {
  margin-right: 5px;
  height: 20px;
}

.surveyTableWrapper th p {
  color: #194fbb !important;
}

.surveyTableWrapper td p {
  font-weight: bold;
}

.surveyTableWrapper img {
  object-fit: cover !important;
}

.CallBackWrapper button {
  margin-top: 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.CallBackWrapper .callBackDate {
  color: #939393;
}

body .statusButton {
  color: #108572 !important;
  background: rgba(16, 133, 114, 0.1);
  border-radius: 50px;
  padding: 12px 10px;
}

.statusButton.paused {
  background: rgba(193, 42, 49, 0.1);
  color: rgba(193, 42, 49, 1) !important;
}

.uploadCsvBtn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0px !important;
}

.smsSurveyEventPopup label {
  font-weight: bold;
}

.eventPopupButtonWrapper .cancel-btn button {
  padding: 6px 20px;
}

.createEventPopupWrapper h2 {
  color: #194fbb !important;
  font-size: 19px;
  font-weight: bold;
}

.createEventPopupWrapper .eventPopupButtonWrapper {
  padding: 20px 24px;
}

.createEventPopupWrapper #responsive-dialog-title {
  padding: 26px 24px;
  padding-bottom: 16px;
}

.createEventPopupWrapper label {
  font-weight: bold;
}

.createEventPopupWrapper .MuiDialog-paperWidthMd {
  max-width: 800px;
}

.createEventPopupWrapper .largeButton {
  height: 44px;
}

.surveyMainWrapper .main-screen-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input:focus-visible {
  outline: none;
}

.csv-sms-buttons {
  position: relative;
}

.csv-sms-buttons button {
  color: white !important;
  background: #003399 !important;
  box-shadow: 0px 4px 10px rgb(0 51 153 / 19%);
  border-radius: 3px;
}

.csv-sms-buttons button svg {
  margin-left: 10px;
}

.selectFormDropdown .MuiAutocomplete-clearIndicator {
  display: none;
}

.csv-sms-buttons .MuiInput-fullWidth {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  display: block;
  opacity: 0;
}
