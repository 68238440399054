.fullContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgetPassword {
  text-align: center;

  color: #323c47 !important;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.officerLogo {
  width: 300px;
  height: 100px;
  object-fit: contain;
}

.backToLogin {
  text-align: center;

  color: #323c47 !important;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.height-100vh {
  min-height: 100vh;
}
