.removRadius {
  border-radius: 0px;
}

.labelStyle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1c1d21 !important;
}

.hintStyle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #a6a8b0 !important;
}

@media screen and (max-width: 1366px) {
  .labelStyle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #1c1d21 !important;
  }

  .hintStyle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #a6a8b0 !important;
  }
}

@media screen and (max-width: 600px) {
}
