.generate-survey {
  max-width: 500px;
  width: 40%;
  padding: 11px 31px 35px 9px;
}

.generate-survey .generate-survey-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  color: #1c1d21;
}

.generate-survey .survey-title {
  margin-top: 12px;
}

.generate-survey .survey-title .title-field {
  height: 60px;
  background: #ffffff;
  border: 1px solid #a6a8b0;
  border-radius: 8px;
  padding: 16px;
}

.generate-survey .survey-title .title-field:focus {
  border: 2px solid #00349a;
}

.generate-survey .survey-instruction {
  margin-top: 28px;
}

.generate-survey .survey-instruction .instruction-field {
  height: 90px;
  background: #ffffff;
  border: 1px solid #a6a8b0;
  border-radius: 8px;
  padding: 16px;
}

.generate-survey .survey-instruction .instruction-field:focus {
  border: 2px solid #00349a;
  outline: none;
}

.generate-survey .survey-type {
  margin-top: 40px;
}

.generate-survey .survey-type .type-field {
  height: 100px;
  background: #ffffff;
  border: 1px solid #a6a8b0;
  border-radius: 8px;
  padding: 16px;
}

.generate-survey .survey-type .type-field:focus {
  border: 2px solid #00349a;
  outline: none;
}

.generate-survey .target-audience {
  margin-top: 40px;
}

.generate-survey .target-audience .audience-field {
  height: 60px;
  background: #ffffff;
  border: 1px solid #a6a8b0;
  border-radius: 8px;
  padding: 16px;
}

.generate-survey .target-audience .audience-field:focus {
  border: 2px solid #00349a;
}

.survey-divider {
  margin-top: 67px;
  margin-bottom: 24px;
}

.generate-results {
  margin-top: -24px;
  max-width: 928px;
  max-height: 891px;
  width: 60%;
  height: 891px;
  background-color: #ffffff;
  border-left: #bfc0c9 1px solid;
  padding: 35px 24px 24px;
}

.generate-results .result-box {
  max-height: 712px;
  width: 100%;
  height: 100%;
  border-bottom: #bfc0c9 1px solid;
  overflow-y: auto;
  overflow-x: auto;
}

.generate-results .results-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  color: #1c1d21;
}

@media screen and (max-width: 1366px) {
  .generate-survey {
    padding: 11px 15px 15px 0px;
    width: 40%;
  }

  .generate-survey .generate-survey-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #1c1d21;
  }

  .generate-survey .survey-title {
    margin-top: 0px;
  }

  .generate-survey .survey-title .title-field {
    height: 54px;
    background: #ffffff;
    border: 1px solid #a6a8b0;
    border-radius: 8px;
    padding: 14px;
  }

  .generate-survey .survey-instruction {
    margin-top: 0px;
  }

  .generate-survey .survey-instruction .instruction-field {
    height: 70px;
    background: #ffffff;
    border: 1px solid #a6a8b0;
    border-radius: 8px;
    padding: 14px;
  }

  .generate-survey .survey-type {
    margin-top: 0px;
  }

  .generate-survey .survey-type .type-field {
    height: 90px;
    background: #ffffff;
    border: 1px solid #a6a8b0;
    border-radius: 8px;
    padding: 14px;
  }

  .generate-survey .target-audience {
    margin-top: 0px;
  }

  .generate-survey .target-audience .audience-field {
    height: 54px;
    background: #ffffff;
    border: 1px solid #a6a8b0;
    border-radius: 8px;
    padding: 14px;
  }

  .survey-divider {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .generate-results {
    margin-top: -24px;
    max-width: 762px;
    max-height: 891px;
    width: 60%;
    height: 720px;
    background-color: #ffffff;
    border-left: #bfc0c9 1px solid;
    padding: 35px 24px 24px;
  }

  .generate-results .result-box {
    max-height: 557px;
    width: 100%;
    height: 100%;
    border-bottom: #bfc0c9 1px solid;
    overflow-y: auto;
    overflow-x: auto;
  }

  .generate-results .results-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #1c1d21;
  }
}

@media screen and (max-width: 600px) {
  .generate-survey {
    padding: 0;
    width: 100%;
  }

  .generate-survey .survey-title {
    margin-top: 16px;
  }

  .generate-survey .survey-title .title-field {
    height: 48px;
  }

  .generate-survey .survey-instruction {
    margin-top: 24px;
  }

  .generate-survey .survey-instruction .instruction-field {
    height: 70px;
  }

  .generate-survey .survey-type {
    margin-top: 32px;
  }

  .generate-survey .survey-type .type-field {
    height: 90px;
  }

  .generate-survey .target-audience {
    margin-top: 32px;
  }

  .generate-survey .target-audience .audience-field {
    height: 48px;
  }

  .survey-divider {
    margin-top: 24px;
  }

  .generate-results {
    margin-top: 0px;
    width: 100%;
    min-width: 390px;
  }
}
