.support-img-upload {
  background-color: #ffffff;
  padding: 8px;
  margin: 16px 0;
  border-radius: 8px;
}

.support-img-upload > label > div > img {
  width: 60px;
  height: 60px;
}
