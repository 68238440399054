.update-officer-inputfield {
  border: 1px solid #c4c4c4;
}

.update-officer-inputfield:hover {
  border: 1px solid #323c47;
}

.update-officer-autocomplete > div {
  border-radius: 12px !important;
}

.update-officer-autocomplete > label {
  font-size: 16px !important;
}

.wrapeRoles {
  border: 1px solid #c4c4c4;
  border-radius: 12px ;
  padding: 10px;
  background-color: #fff;
}
.wrapeRoles .MuiIconButton-label{
  visibility: visible;
}

.wrapeRoles .toggelButtonWrape{
  display: flex;
  justify-content: space-between;
}

.wrapeRoles h5{
  padding-top: 5px;
  font-family: 'Poppins', sans-serif !important;
  color: #323c47 !important;
  font-size: 16px;
}

.wrapLabel {
  font-family: 'Poppins', sans-serif !important;
  color: #323c47 !important;
  font-size: 16px;
}